import { render, staticRenderFns } from "./internship_report.vue?vue&type=template&id=2788c929&scoped=true&"
import script from "./internship_report.vue?vue&type=script&lang=js&"
export * from "./internship_report.vue?vue&type=script&lang=js&"
import style0 from "./internship_report.vue?vue&type=style&index=0&id=2788c929&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2788c929",
  null
  
)

export default component.exports