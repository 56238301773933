import request from '@/utils/request'

export default {
  // 获取字数
  getWordsNum(){
    return request({
      url: '/internship/getWordsNum',
      method: 'get'
    })
  }
}
